import { Link, useNavigate, useParams } from "react-router";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
  CButton,
  CButtonGroup,
  CLoadingButton,
} from "@coreui/react-pro";
import { useMutation, useQuery } from "@apollo/client";
import { formatCurrency } from "src/helpers/numbers";
import { Daruma, Rappi } from "src/components/Icon";
import { GraphQLFind, GraphQLMeta } from "src/types";
import Api from "src/api";
import PlanAlert from "src/containers/PlanAlert";
import { useEffect, useMemo, useState } from "react";
import { RappiProduct } from "src/api/rappi";
import { Store } from "src/api/stores";

enum TabIndex {
  All = 1,
  MissingInDaruma = 2,
  OutdatedPrice = 3,
  OutdatedStock = 4,
}

const RappiAudit = () => {
  const navigate = useNavigate();
  const params = useParams();
  const storeId = Number(params.storeId);
  const [currentTab, setCurrentTab] = useState<TabIndex>(TabIndex.All);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  if (!storeId) {
    navigate("/rappi");
  }

  const queryParams = {
    variables: {
      filters: {
        storeId,
        limit: 0,
      },
    },
  };

  const { data: store } = useQuery<GraphQLFind<Store>>(Api.Stores.GET_STORE, {
    fetchPolicy: "no-cache",
    variables: {
      id: storeId,
    },
  });

  const { data: items, loading } = useQuery<GraphQLMeta<RappiProduct>>(
    Api.Rappi.LIST_PRODUCTS,
    {
      fetchPolicy: "no-cache",
      ...queryParams,
    }
  );

  const [syncProductMutation, { loading: updating }] = useMutation(
    Api.Rappi.SYNC_PRODUCT,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  const [syncProductsMutation, { loading: updatings }] = useMutation(
    Api.Rappi.SYNC_PRODUCTS,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  const [downloadMutation, { loading: downloading }] = useMutation(
    Api.Rappi.DOWNLOAD_RAPPI_PRODUCT,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  useEffect(() => {
    setSelectedItems([]);
  }, [currentTab]);

  const productList = useMemo(() => {
    if (!items?.data.data) {
      return [];
    }

    if (currentTab === TabIndex.All) {
      return items?.data.data;
    }

    if (currentTab === TabIndex.MissingInDaruma) {
      return items?.data.data.filter((item) => !item.priceListItem);
    }

    if (currentTab === TabIndex.OutdatedPrice) {
      return items?.data.data.filter(
        (item) => item.priceListItem.price !== item.price
      );
    }

    if (currentTab === TabIndex.OutdatedStock) {
      return items?.data.data.filter((item) => item.darumaStock !== item.stock);
    }

    return [];
  }, [currentTab, items?.data.data]);

  const rest: any[] = [];

  if (currentTab !== TabIndex.All) {
    rest.push({
      key: "actions",
      label: "Acciones",
      _props: { className: "text-right" },
      filter: false,
    });
  }

  const multiSelection = selectedItems.length > 0;
  const currentStore = store?.data;

  return (
    <>
      <PlanAlert />

      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow className="align-items-center justify-content-center">
                <CCol sm="6" xs="6" className="px-0">
                  {`Auditoria de Productos: Daruma <-> Rappi: ${
                    currentStore?.name ?? ""
                  }`}
                </CCol>
                <CCol sm="6" xs="6" className="row justify-content-end"></CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm={12} className="flex">
                  <CButtonGroup size="sm" role="group">
                    <CButton
                      color="primary"
                      variant={
                        currentTab === TabIndex.All ? undefined : "outline"
                      }
                      onClick={() => setCurrentTab(TabIndex.All)}
                    >
                      Todos los Productos
                    </CButton>
                    <CButton
                      color="primary"
                      variant={
                        currentTab === TabIndex.MissingInDaruma
                          ? undefined
                          : "outline"
                      }
                      onClick={() => setCurrentTab(TabIndex.MissingInDaruma)}
                    >
                      Inexistentes en Daruma
                    </CButton>
                    <CButton
                      color="primary"
                      variant={
                        currentTab === TabIndex.OutdatedPrice
                          ? undefined
                          : "outline"
                      }
                      onClick={() => setCurrentTab(TabIndex.OutdatedPrice)}
                    >
                      Precios desactualizados
                    </CButton>

                    <CButton
                      color="primary"
                      variant={
                        currentTab === TabIndex.OutdatedStock
                          ? undefined
                          : "outline"
                      }
                      onClick={() => setCurrentTab(TabIndex.OutdatedStock)}
                    >
                      Stock desactualizado
                    </CButton>
                  </CButtonGroup>

                  <div className="flex justify-content-end">
                    {multiSelection && (
                      <>
                        {[
                          TabIndex.OutdatedPrice,
                          TabIndex.OutdatedStock,
                        ].includes(currentTab) && (
                          <CLoadingButton
                            color="success"
                            size="sm"
                            disabled={!multiSelection}
                            onClick={() => {
                              if (!updatings && multiSelection) {
                                syncProductsMutation({
                                  variables: {
                                    itemIds: productList
                                      .filter((p) =>
                                        selectedItems.includes(p.id)
                                      )
                                      .map((p) => p.priceListItem.id),
                                  },
                                });
                              }
                            }}
                          >
                            {updatings
                              ? "Actualizando..."
                              : "Actualizar Productos"}
                          </CLoadingButton>
                        )}
                      </>
                    )}
                  </div>
                </CCol>
              </CRow>

              <CSmartTable
                className="mt-2"
                itemsPerPage={20}
                items={productList}
                selected={productList.filter((p) =>
                  selectedItems.includes(p.id)
                )}
                selectable={
                  [TabIndex.OutdatedPrice].includes(currentTab) &&
                  productList.length > 1
                }
                onSelectAll={() => {
                  setSelectedItems(productList.map((item) => item.id));
                }}
                onSelectedItemsChange={(e) => {
                  setSelectedItems(e.map((i) => i.variantId));
                }}
                pagination
                columns={[
                  {
                    key: "name",
                    label: "Nombre",
                    _props: { className: "font-weight-bold" },
                  },
                  {
                    key: "stock",
                    label: "Stock",
                    _props: { className: "text-center" },
                    filter: false,
                  },
                  {
                    key: "price",
                    label: "Precio",
                    _props: { className: "text-right" },
                    filter: false,
                  },
                  ...rest,
                ]}
                columnFilter={{
                  lazy: true,
                }}
                loading={loading}
                scopedColumns={{
                  name: (item: RappiProduct) => (
                    <td>
                      <div className="flex align-items-center">
                        <Daruma />
                        <span className="ml-2">
                          {item.priceListItem ? (
                            <Link
                              to={`/products/${item.priceListItem.productId}`}
                              target="_blank"
                            >
                              {item.priceListItem.product.name} -{" "}
                              <small>
                                SKU: {item.priceListItem.product.sku}
                              </small>
                            </Link>
                          ) : (
                            "-"
                          )}
                        </span>
                      </div>
                      <div className="flex align-items-center mt-1">
                        <Rappi size={24} />
                        <span className="ml-2">
                          {item.name} - <small>SKU: {item.sku}</small>
                        </span>
                      </div>
                    </td>
                  ),

                  stock: (item: RappiProduct) => (
                    <td align="right">
                      <div className="flex justify-content-end">
                        {item.priceListItem ? (
                          <>
                            <Daruma />
                            <span className="ml-2">
                              {item.priceListItem.product.stockeable
                                ? item.darumaStock
                                : "-"}
                            </span>
                          </>
                        ) : (
                          <span className="ml-2">-</span>
                        )}
                      </div>
                      <div className="flex justify-content-end mt-1">
                        <Rappi size={24} />
                        <span className="ml-2">{item.stock}</span>
                      </div>
                    </td>
                  ),

                  price: (item: RappiProduct) => (
                    <td align="right">
                      <div className="flex justify-content-end">
                        {item.priceListItem ? (
                          <>
                            <Daruma />
                            <span className="ml-2">
                              {formatCurrency(item.priceListItem.product.price)}
                            </span>
                          </>
                        ) : (
                          <span className="ml-2">-</span>
                        )}
                      </div>
                      <div className="flex justify-content-end mt-1">
                        <Rappi size={24} />
                        <span className="ml-2">
                          {formatCurrency(item.price)}
                        </span>
                      </div>
                    </td>
                  ),
                  actions: (item: RappiProduct) => {
                    const hasDiffPrice =
                      item.priceListItem?.product.price !== 0;
                    const hasDiffStock =
                      item.darumaStock >= 0 && item.darumaStock !== item.stock;
                    const isDownloaded = item.priceListItem !== null;

                    return (
                      <td align="right">
                        {currentTab === TabIndex.MissingInDaruma &&
                          !isDownloaded && (
                            <Link
                              to=""
                              onClick={() => {
                                if (!downloading) {
                                  downloadMutation({
                                    variables: {
                                      storeId,
                                      sku: item.sku,
                                      price: item.price,
                                    },
                                  });
                                }
                              }}
                            >
                              <small>
                                {downloading ? "Descargando" : "Descargar"}
                              </small>
                            </Link>
                          )}

                        {currentTab === TabIndex.OutdatedPrice &&
                          isDownloaded &&
                          hasDiffPrice && (
                            <Link
                              to=""
                              onClick={() => {
                                if (!updating && !multiSelection) {
                                  syncProductMutation({
                                    variables: {
                                      itemId: item.priceListItem.id,
                                    },
                                  });
                                }
                              }}
                              style={{ color: multiSelection ? "gray" : "" }}
                            >
                              <small>
                                {updating
                                  ? "Actualizando"
                                  : "Actualizar Precio"}
                              </small>
                            </Link>
                          )}

                        {currentTab === TabIndex.OutdatedStock &&
                          isDownloaded &&
                          hasDiffStock && (
                            <Link
                              to=""
                              onClick={() => {
                                if (!updating && !multiSelection) {
                                  syncProductMutation({
                                    variables: {
                                      itemId: item.priceListItem.id,
                                    },
                                  });
                                }
                              }}
                              style={{ color: multiSelection ? "gray" : "" }}
                            >
                              <small>
                                {updating ? "Actualizando" : "Actualizar Stock"}
                              </small>
                            </Link>
                          )}
                      </td>
                    );
                  },
                }}
                tableProps={{
                  striped: true,
                  hover: true,
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default RappiAudit;
