import { useMutation, useQuery } from "@apollo/client";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CLoadingButton,
  CRow,
  CSmartTable,
  CTooltip,
} from "@coreui/react-pro";
import { FooterItem } from "@coreui/react-pro/dist/esm/components/smart-table/types";
import { useFormik } from "formik";
import { useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Api from "src/api";
import { Product } from "src/api/products";
import {
  AddShipmentItemSchema,
  Shipment,
  ShipmentItem,
  ShipmentStatus,
} from "src/api/shipments";

import { AppLoader } from "src/components/Loader/Loader";
import SmartSelect from "src/components/SmartSelect";
import { logEvent } from "src/helpers/analytics";
import { formatCurrency } from "src/helpers/numbers";
import { useAdminStore } from "src/store";
import { Generic, GraphQLFind, GraphQLMeta } from "src/types";
import ShipmentProgressBar from "./components/ShipmentStatusBar";
import classNames from "classnames";
import { getCurrentQuantity, getCurrentTotal } from "src/helpers/shipments";
import ShipmentToWarehouseModal from "./components/ShipmentToWarehouseModal";
import { LoaderCircle, Trash } from "lucide-react";

const ShipmentForm = () => {
  const shipmentToWarehouseModalRef = useRef<{ open: () => void }>(null);
  const quantityRef = useRef<HTMLInputElement>(null);
  const params = useParams();
  const supplierId = Number(params.id);
  const shipmentId = Number(params.shipmentId);
  const navigate = useNavigate();
  const { currentCompany } = useAdminStore();
  const [shippedItems, setShippedItems] = useState<ShipmentItem[]>();

  if (!supplierId) {
    navigate("/suppliers");
  }

  if (!shipmentId) {
    navigate(`/suppliers/${supplierId}/shipments`);
  }

  const { data: productsList } = useQuery<GraphQLMeta<Product>>(
    Api.Products.LIST_PRODUCTS,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          companyId: currentCompany?.id,
          supplierId,
          limit: 0,
          trashed: 0,
        },
      },
    }
  );

  const { data: shipmentData, refetch } = useQuery<GraphQLFind<Shipment>>(
    Api.Shipments.GET_SHIPMENT,
    {
      variables: {
        id: shipmentId,
      },
      skip: !shipmentId,
      fetchPolicy: "no-cache",
      onCompleted: ({ data }) => {
        setShippedItems(data.items);
      },
    }
  );

  const [addItem, { loading: adding }] = useMutation(
    Api.Shipments.ADD_ITEM_TO_SHIPMENT,
    {
      onCompleted: () => {
        (
          document.querySelector(".select-search-input") as HTMLInputElement
        ).focus();
        refetch();
        formik.resetForm();
      },
    }
  );

  const [deleteShipmentItem, { loading: deleting }] = useMutation(
    Api.Shipments.DELETE_ITEM_FROM_SHIPMENT,
    {
      onCompleted: () => {
        refetch();
        formik.resetForm();
      },
    }
  );
  const [updateShipmentMutation, { loading: updating }] = useMutation(
    Api.Shipments.UPDATE_SHIPMENT,
    {
      onCompleted: () => {
        refetch();
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      productId: 0,
      cost: 0,
      tax: 0,
      quantity: "",
    },
    onSubmit: (data) => {
      const input = {
        shipmentId: shipmentId,
        productId: data.productId,
        cost: data.cost,
        tax: data.tax,
        quantity: data.quantity,
      };

      logEvent("shipments.add-item", { input });

      addItem({
        variables: {
          input,
        },
      });
    },
    validateOnMount: true,
    validationSchema: AddShipmentItemSchema,
  });

  const products = useMemo(() => {
    if (!productsList?.data.data) {
      return [];
    }

    return productsList.data.data.map((product) => ({
      name: `${product.name} - ${
        product.barcodes.length > 0
          ? product.barcodes.map((b) => b.barcode).join(", ")
          : "Sin código de barra"
      } `,
      value: product.id,
    }));
  }, [productsList]);

  if (!shipmentData?.data) {
    return <AppLoader />;
  }

  const shipment = shipmentData?.data;

  const columns = [
    {
      key: "product",
      label: "Producto",
      _props: { className: "font-weight-bold" },
    },
    {
      key: "quantity",
      label: "Cantidad",
      _props: { className: "text-center font-weight-bold" },
    },
    {
      key: "price",
      label: "Costo",
      _props: { className: "text-right font-weight-bold" },
    },
    {
      key: "subtotal",
      label: "Subtotal",
      _props: { className: "text-right font-weight-bold" },
    },
  ];

  if (shipment.status === ShipmentStatus.Open) {
    columns.push({
      key: "actions",
      label: "Acciones",
      _props: { className: "text-right" },
    });
  }

  if (shipment.status === ShipmentStatus.Sent) {
    columns.push({
      key: "orderedQuantity",
      label: "Cant. Solicitada",
      _props: { className: "text-right" },
    });

    columns.push({
      key: "orderedPrice",
      label: "Costo",
      _props: { className: "text-right" },
    });
  }

  if (shipment.status === ShipmentStatus.Shipped) {
    columns.push({
      key: "confirmedQuantity",
      label: "Cant. Recibida",
      _props: { className: "text-right" },
    });

    columns.push({
      key: "confirmedPrice",
      label: "Costo Recibido",
      _props: { className: "text-right" },
    });
  }

  const generateFooter = () => {
    const footerStack: (FooterItem | string)[] = [
      {
        label: "Totales",
      },
      {
        label: `${shipment.items.reduce(
          (acc, item) => acc + getCurrentQuantity(item, shipment.status),
          0
        )} items`,
        _props: { className: "text-center" },
      },
      "",
    ];

    footerStack.push({
      label: formatCurrency(
        shipment.items.reduce(
          (acc, item) => acc + getCurrentTotal(item, shipment.status),
          0
        )
      ),
      _props: { className: "text-right" },
    });

    if (shipment.status === ShipmentStatus.Open) {
      footerStack.push("");
    }

    if (shipment.status === ShipmentStatus.Sent) {
      footerStack.push({
        label: `${(shippedItems ?? []).reduce(
          (acc, item) => acc + getCurrentQuantity(item, shipment.status),
          0
        )}`,
        _props: { className: "text-right" },
      });

      footerStack.push({
        label: formatCurrency(
          (shippedItems ?? []).reduce(
            (acc, item) => acc + getCurrentTotal(item, shipment.status),
            0
          )
        ),
        _props: { className: "text-right" },
      });
    }

    if (shipment.status === ShipmentStatus.Shipped) {
      footerStack.push({
        label: `${(shippedItems ?? []).reduce(
          (acc, item) => acc + getCurrentQuantity(item, shipment.status),
          0
        )}`,
        _props: { className: "text-right" },
      });

      footerStack.push({
        label: formatCurrency(
          (shippedItems ?? []).reduce(
            (acc, item) => acc + getCurrentTotal(item, shipment.status),
            0
          )
        ),
        _props: { className: "text-right" },
      });
    }

    return footerStack;
  };

  const updateItem = (
    prevItems: ShipmentItem[],
    item: ShipmentItem,
    key: string,
    value: number
  ) => {
    if (prevItems) {
      return prevItems.map((currItem) => {
        if (currItem.id === item.id) {
          return {
            ...currItem,
            [key]: value,
          };
        }

        return currItem;
      });
    }

    return prevItems;
  };

  return (
    <>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow className="align-items-center justify-content-center">
                <CCol sm={12}>Pedido #{shipmentId}</CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <ShipmentProgressBar status={shipment.status} />

              {shipment.status === ShipmentStatus.Open && (
                <CForm className="mb-3" onSubmit={formik.handleSubmit}>
                  <CRow>
                    <CCol className="product-search" sm={5}>
                      <CFormLabel>Producto</CFormLabel>
                      <SmartSelect
                        name="productId"
                        search
                        placeholder="Selecciona un producto"
                        emptyLabel="No tienes productos stockeables"
                        options={products}
                        onChange={(e) => {
                          const product = productsList?.data.data.find(
                            (p) => p.id === e
                          );

                          if (product) {
                            formik.setValues({
                              productId: product.id,
                              cost: product.cost,
                              tax: product.tax,
                              quantity: formik.values.quantity,
                            });

                            quantityRef.current?.focus();
                          }
                        }}
                        value={formik.values.productId.toString()}
                      />
                    </CCol>
                    <CCol sm={2}>
                      <CFormLabel>Costo</CFormLabel>
                      <CFormInput
                        value={formik.values.cost}
                        name="cost"
                        type="number"
                        onChange={formik.handleChange}
                      />
                    </CCol>
                    <CCol sm={2}>
                      <CFormLabel>Cantidad</CFormLabel>
                      <CFormInput
                        value={formik.values.quantity}
                        name="quantity"
                        ref={quantityRef}
                        type="number"
                        onChange={formik.handleChange}
                      />
                    </CCol>
                    <CCol
                      sm={3}
                      className="d-flex align-items-end justify-content-end"
                    >
                      <CLoadingButton
                        color="primary"
                        type="submit"
                        size="sm"
                        loading={adding}
                        disabled={adding}
                        onClick={formik.handleSubmit}
                      >
                        Agregar
                      </CLoadingButton>
                    </CCol>
                  </CRow>
                </CForm>
              )}

              <CSmartTable
                itemsPerPage={shipment.items.length ?? 0}
                items={shipment.items ?? []}
                columns={columns}
                footer={generateFooter()}
                noItemsLabel={
                  shipment.status === ShipmentStatus.Open
                    ? "Empieza a agregar productos al pedido"
                    : "No tienes items en este pedido"
                }
                scopedColumns={{
                  product: (item: ShipmentItem) => <td>{item.product.name}</td>,
                  quantity: (item: ShipmentItem) => (
                    <td align="center">
                      <OldNewValue
                        status={shipment.status}
                        ordered={item.orderedQuantity}
                        confirmed={item.confirmedQuantity}
                        delivered={item.deliveredQuantity}
                      />
                    </td>
                  ),
                  price: (item: ShipmentItem) => (
                    <td align="right">
                      <OldNewValue
                        currency
                        status={shipment.status}
                        ordered={item.orderedPrice}
                        confirmed={item.confirmedPrice}
                        delivered={item.deliveredPrice}
                      />
                    </td>
                  ),
                  subtotal: (item: ShipmentItem) => (
                    <td align="right">
                      {formatCurrency(getCurrentTotal(item, shipment.status))}
                    </td>
                  ),
                  orderedQuantity: (item: ShipmentItem) => {
                    return (
                      <td align="right" width={150}>
                        <CFormInput
                          defaultValue={item.orderedQuantity}
                          type="number"
                          max={item.orderedQuantity}
                          readOnly={item.orderedQuantity === 0}
                          min={0}
                          onChange={(e) => {
                            setShippedItems((prevItems) =>
                              updateItem(
                                prevItems ?? [],
                                item,
                                "orderedQuantity",
                                Number(e.target.value)
                              )
                            );
                          }}
                          className="text-right"
                        />
                      </td>
                    );
                  },
                  orderedPrice: (item: ShipmentItem) => {
                    return (
                      <td align="right" width={150}>
                        <CFormInput
                          defaultValue={item.orderedPrice}
                          type="number"
                          readOnly={item.orderedQuantity === 0}
                          min={0}
                          onChange={(e) => {
                            setShippedItems((prevItems) =>
                              updateItem(
                                prevItems ?? [],
                                item,
                                "orderedPrice",
                                Number(e.target.value)
                              )
                            );
                          }}
                          className="text-right"
                        />
                      </td>
                    );
                  },
                  confirmedQuantity: (item: ShipmentItem) => {
                    return (
                      <td align="right" width={130}>
                        <CFormInput
                          defaultValue={item.confirmedQuantity}
                          type="number"
                          max={item.confirmedQuantity}
                          readOnly={item.confirmedQuantity === 0}
                          min={0}
                          onChange={(e) => {
                            setShippedItems((prevItems) =>
                              updateItem(
                                prevItems ?? [],
                                item,
                                "confirmedQuantity",
                                Number(e.target.value)
                              )
                            );
                          }}
                          className="text-right"
                        />
                      </td>
                    );
                  },
                  confirmedPrice: (item: ShipmentItem) => {
                    return (
                      <td align="right" width={150}>
                        <CFormInput
                          defaultValue={item.confirmedPrice}
                          type="number"
                          className="text-right"
                          min={0}
                          readOnly={item.confirmedQuantity === 0}
                          onChange={(e) => {
                            setShippedItems((prevItems) =>
                              updateItem(
                                prevItems ?? [],
                                item,
                                "confirmedPrice",
                                Number(e.target.value)
                              )
                            );
                          }}
                        />
                      </td>
                    );
                  },
                  actions: (item: ShipmentItem) => (
                    <td align="right">
                      <CTooltip content={"Eliminar item"}>
                        <CButton
                          onClick={() =>
                            deleteShipmentItem({ variables: { id: item.id } })
                          }
                          size="sm"
                          color="danger"
                        >
                          {deleting ? <LoaderCircle /> : <Trash />}
                        </CButton>
                      </CTooltip>
                    </td>
                  ),
                }}
              />
            </CCardBody>

            {![
              ShipmentStatus.Cancelled,
              ShipmentStatus.Rejected,
              ShipmentStatus.Returned,
            ].includes(shipment.status) && (
              <CCardFooter>
                <CCol className="text-right">
                  {shipment.status === ShipmentStatus.Open && (
                    <FooterButton
                      loading={updating}
                      onClick={updateShipmentMutation}
                      shipmentId={shipment.id}
                      status={ShipmentStatus.Closed}
                      text="Cerrar Pedido"
                    />
                  )}

                  {shipment.status === ShipmentStatus.Closed && (
                    <FooterButton
                      loading={updating}
                      onClick={updateShipmentMutation}
                      shipmentId={shipment.id}
                      status={ShipmentStatus.Sent}
                      text="Enviar Pedido"
                    />
                  )}

                  {shipment.status === ShipmentStatus.Sent && (
                    <>
                      <FooterButton
                        loading={updating}
                        onClick={updateShipmentMutation}
                        shipmentId={shipment.id}
                        status={ShipmentStatus.Rejected}
                        text="Rechazar Pedido"
                        danger
                      />
                      <FooterButton
                        loading={updating}
                        onClick={updateShipmentMutation}
                        shipmentId={shipment.id}
                        status={ShipmentStatus.Confirmed}
                        text="Confirmar Pedido"
                      />
                    </>
                  )}

                  {shipment.status === ShipmentStatus.Confirmed && (
                    <FooterButton
                      onClick={updateShipmentMutation}
                      text="Pedido en reparto"
                      shipmentId={shipment.id}
                      status={ShipmentStatus.Shipped}
                      loading={updating}
                    />
                  )}

                  {shipment.status === ShipmentStatus.Shipped && (
                    <FooterButton
                      onClick={updateShipmentMutation}
                      text="Marcar como recibido"
                      shipmentId={shipment.id}
                      status={ShipmentStatus.Delivered}
                      loading={updating}
                      items={shippedItems ?? []}
                    />
                  )}

                  {shipment.status === ShipmentStatus.Delivered && (
                    <CLoadingButton
                      type="button"
                      size="sm"
                      color="primary"
                      onClick={() => {
                        if (shipmentToWarehouseModalRef.current) {
                          shipmentToWarehouseModalRef.current?.open();
                        }
                      }}
                    >
                      Crear Ingreso
                    </CLoadingButton>
                  )}
                </CCol>
              </CCardFooter>
            )}
          </CCard>
        </CCol>
      </CRow>

      <ShipmentToWarehouseModal
        shipmentId={shipment.id}
        ref={shipmentToWarehouseModalRef}
      />
    </>
  );
};

const FooterButton = ({
  onClick,
  loading,
  shipmentId,
  items = [],
  status,
  danger = false,
  text,
}: {
  onClick: (p: Generic) => void;
  loading: boolean;
  shipmentId: number;
  text: string;
  danger?: boolean;
  items?: ShipmentItem[];
  status: ShipmentStatus;
}) => {
  return (
    <CLoadingButton
      type="button"
      size="sm"
      className="ml-2"
      color={danger ? "danger" : "primary"}
      loading={loading}
      onClick={() => {
        if (!loading) {
          let updatedItems: Generic[] = [];

          if (status === ShipmentStatus.Confirmed) {
            updatedItems = items.map((item) => ({
              shipmentId,
              productId: item.productId,
              shipmentItemId: item.id,
              cost: item.orderedPrice,
              quantity: item.orderedQuantity,
              tax: item.orderedTax,
            }));
          }

          if (status === ShipmentStatus.Delivered) {
            updatedItems = items.map((item) => ({
              shipmentId,
              productId: item.productId,
              shipmentItemId: item.id,
              cost: item.confirmedPrice,
              quantity: item.confirmedQuantity,
              tax: item.confirmedTax,
            }));
          }

          onClick({
            variables: {
              id: shipmentId,
              input: {
                status,
                items: updatedItems,
              },
            },
          });
        }
      }}
    >
      {text}
    </CLoadingButton>
  );
};

const OldNewValue = ({
  status,
  currency = false,
  ordered,
  confirmed,
  delivered,
}) => {
  const formatter = currency ? formatCurrency : Number;

  if (
    [
      ShipmentStatus.Open,
      ShipmentStatus.Closed,
      ShipmentStatus.Sent,
      ShipmentStatus.Cancelled,
    ].includes(status)
  ) {
    return formatter(ordered);
  }

  if (
    [
      ShipmentStatus.Confirmed,
      ShipmentStatus.Shipped,
      ShipmentStatus.Rejected,
      ShipmentStatus.Returned,
    ].includes(status)
  ) {
    return (
      <div
        className={classNames("d-flex align-items-center", {
          "justify-content-end": currency,
          "justify-content-center": !currency,
        })}
      >
        {ordered !== confirmed && (
          <span
            style={{
              textDecoration: "line-through",
              marginRight: 4,
              fontSize: 12,
            }}
          >
            {formatter(ordered)}
          </span>
        )}
        <span>{formatter(confirmed)}</span>
      </div>
    );
  }

  return (
    <div
      className={classNames("d-flex align-items-center", {
        "justify-content-end": currency,
        "justify-content-center": !currency,
      })}
    >
      {ordered !== delivered && (
        <span
          style={{
            textDecoration: "line-through",
            marginRight: 4,
            fontSize: 12,
          }}
        >
          {formatter(ordered)}
        </span>
      )}
      <span>{formatter(delivered)}</span>
    </div>
  );
};

export default ShipmentForm;
