import { PriceList } from "./price-lists";
import { CashRegister } from "./registers";
import { gql } from "@apollo/client";
import { Company } from "./companies";
import yup from "src/helpers/validation";
import { RoundingConfig } from "src/types";
import { StockType } from "./stock";
import { Sector } from "./sectors";
import request from "./request";

export const StoreSchema = yup.object().shape({
  companyId: yup.string().integer().required(),
  name: yup.string().required(),
  streetName: yup.string().nullable(),
  streetNumber: yup.string().nullable(),
  phone: yup.string().nullable(),
});

export type StoreConfig = RoundingConfig & {
  invoiceTypes: {
    id: number;
    name: string;
    anonymous: boolean;
  }[];
  paymentMethod: string;
  profit: number;
  stock: StockType;
  posId: number;
  calculate: string;
  stickersPaperSize: string;
  autoPropagate: boolean;
  displayStock: boolean;
  bankPromotions: number[];
};

export enum StoreType {
  Digital = "DIGITAL",
  Store = "STORE",
  TiendaNube = "TIENDA_NUBE",
  MercadoLibre = "MERCADO_LIBRE",
  MercadoShops = "MERCADO_SHOPS",
  Rappi = "RAPPI",
}

export type StoreConnections = {
  tiendaNubeConnected: boolean;
  tiendaNubeId?: number;

  mercadoPagoConnected: boolean;
  mercadoPagoId?: number;

  mercadoLibreConnected: boolean;

  mercadoShopsConnected: boolean;

  rappiConnected: boolean;
};

export type StoreMetadata = {
  mercadoPagoToken?: string;
  mercadoPagoPOSId?: number;
  mercadoShopsUrl?: string;
  tiendaNubeUrl?: string;
  rappiAutoDownloadOrders?: boolean;
};

export type Store = {
  id: number;
  companyId: number;
  sectorId?: number;
  warehouseId?: number;
  name: string;
  streetName: string;
  streetNumber: string;
  city: string;
  province: string;
  phone: string;
  utid: string;
  connections?: StoreConnections;
  metadata?: StoreMetadata;
  subdomain: string;
  website: string;
  logoUrl: string;

  type: StoreType;
  company: Company;
  registers?: CashRegister[];
  priceLists?: PriceList[];
  config: StoreConfig;
  sector?: Sector;
};

export type StoreCreate = Pick<
  Store,
  | "sectorId"
  | "streetName"
  | "streetNumber"
  | "phone"
  | "companyId"
  | "name"
  | "utid"
  | "province"
  | "city"
  | "type"
  | "website"
>;

export type StoreUpdate = Omit<StoreCreate, "type" | "metadata"> &
  Pick<Store, "subdomain" | "website"> & {
    mercadoShopsUrl?: string;
    tiendaNubeUrl?: string;
  };

export const LIST_STORES = gql`
  query getStores($filters: StoreFilters) {
    data: stores(filters: $filters) {
      data {
        id
        name
        utid
        phone
        type
        warehouseId

        streetName
        streetNumber
        province
        city

        connections {
          tiendaNubeConnected

          mercadoLibreConnected

          mercadoShopsConnected

          mercadoPagoConnected

          rappiConnected
        }

        metadata {
          mercadoPagoPOSId
          tiendaNubeUrl
          rappiAutoDownloadOrders
        }

        company {
          id
          name
          utid
          isFranchise

          currentPlan {
            type
          }
        }

        sector {
          id
          name
        }

        config {
          invoiceTypes {
            id
            name
            anonymous
          }
          defaultPaymentMethod
          stock
          autoPropagate
          profit
          calculate
          rounding
          roundingMethod {
            floor
            none
            ceil
          }
          posId
          stickersPaperSize
          displayStock
          bankPromotions
        }
      }
      meta {
        total
        lastPage
      }
    }
  }
`;

export const GET_STORE = gql`
  query getStore($id: Int!) {
    data: store(id: $id) {
      id

      name
      streetName
      streetNumber
      province
      city
      phone
      companyId
      utid
      type
      subdomain
      website
      logoUrl

      company {
        name
        utid
        isFranchise
        logoUrl
      }

      sector {
        id
      }

      registers: cashRegisters {
        id
        name
        description

        session {
          openedAt
          closedAt
        }
      }

      connections {
        tiendaNubeConnected
        tiendaNubeId

        mercadoLibreConnected

        mercadoShopsConnected

        mercadoPagoConnected

        rappiConnected
      }

      metadata {
        tiendaNubeUrl
        mercadoShopsUrl
      }

      priceLists {
        id
        name
        dateFrom
        dateTo
        lastSyncAt
        updatedAt
      }
    }
  }
`;

export const CREATE_STORE = gql`
  mutation createStore($input: CreateStoreInput!) {
    data: createStore(input: $input) {
      id
    }
  }
`;

export const UPDATE_STORE = gql`
  mutation updateStore($id: Int!, $input: UpdateStoreInput!) {
    data: updateStore(id: $id, input: $input) {
      id
    }
  }
`;

export const DELETE_STORE = gql`
  mutation deleteStore($id: Int!) {
    data: deleteStore(id: $id) {
      id
    }
  }
`;

export const UPDATE_CONFIG = gql`
  mutation updateStoreConfig($id: Int!, $input: UpdateStoreConfigInput!) {
    data: updateStoreConfig(id: $id, input: $input) {
      id
    }
  }
`;

export const SET_METADATA = gql`
  mutation setStoreMetadata($id: Int!, $input: [SetStoreMetadataInput!]!) {
    data: setStoreMetadata(id: $id, input: $input) {
      id
    }
  }
`;

export const uploadLogo = async (storeId: number, body: FormData) =>
  request.put(`/imports/stores/${storeId}/logo`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
