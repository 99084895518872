import { useAdminStore } from "src/store";
import {
  Afip,
  MercadoLibre,
  MercadoPago,
  MercadoShops,
  Rappi,
  TiendaNube,
} from "src/components/Icon";
import { useMemo } from "react";
import { CNavGroup, CNavItem } from "@coreui/react-pro";
import { StockType } from "src/api/stock";
import { StoreType } from "src/api/stores";
import {
  BadgePercent,
  Banknote,
  Book,
  BookUser,
  Boxes,
  Building2,
  Calculator,
  ChartNetwork,
  CreditCard,
  FileKey2,
  FileText,
  Folder,
  LayoutDashboard,
  MailQuestion,
  Scale,
  ShoppingBag,
  ShoppingCart,
  SquareChartGantt,
  Store,
  TicketPercent,
  // TicketPercent,
  Truck,
  Users,
  Warehouse,
} from "lucide-react";

const useSidebar = () => {
  const { user, hasFlag, currentCompany } = useAdminStore();

  const {
    MeLiCompanies,
    MePaCompanies,
    MeShCompanies,
    TiNuCompanies,
    RappiCompanies,
  } = useMemo(() => {
    const hasMePa = currentCompany?.stores.find(
      (store) => store.connections?.mercadoPagoConnected
    );
    const hasMeLi = currentCompany?.stores.find(
      (store) =>
        store.type === StoreType.MercadoLibre &&
        store.connections?.mercadoLibreConnected
    );
    const hasMeSh = currentCompany?.stores.find(
      (store) =>
        store.type === StoreType.MercadoShops &&
        store.connections?.mercadoShopsConnected
    );
    const hasTiNu = currentCompany?.stores.find(
      (store) =>
        store.type === StoreType.TiendaNube &&
        store.connections?.tiendaNubeConnected
    );
    const hasRappi = currentCompany?.stores.find(
      (store) =>
        store.type === StoreType.Rappi && store.connections?.rappiConnected
    );

    const MeLiCompanies = [hasMeLi ? currentCompany?.id : 0];
    const MePaCompanies = [hasMePa ? currentCompany?.id : 0];
    const MeShCompanies = [hasMeSh ? currentCompany?.id : 0];
    const TiNuCompanies = [hasTiNu ? currentCompany?.id : 0];
    const RappiCompanies = [hasRappi ? currentCompany?.id : 0];

    return {
      MeLiCompanies,
      MePaCompanies,
      MeShCompanies,
      TiNuCompanies,
      RappiCompanies,
    };
  }, [currentCompany]);

  const items = useMemo(() => {
    const top = [] as any;

    if (!user?.isAdmin) {
      const integrationsItem = {
        component: CNavGroup,
        name: "Integraciones",
        to: "/integrations",
        items: [] as any[],
      } as any;

      const inventoryItems = [
        {
          component: CNavItem,
          name: "Categorías",
          to: "/categories",
          icon: <ShoppingBag className="mr-2" />,
          permissionKey: "CREATE_PRODUCT",
        },
        {
          component: CNavItem,
          name: "Productos",
          to: "/products",
          icon: <ShoppingCart className="mr-2" />,
          permissionKey: "LIST_PRODUCTS",
        },
        {
          component: CNavItem,
          name: "Promociones",
          to: "/promotions",
          icon: <BadgePercent className="mr-2" />,
          permissionKey: "CREATE_PRODUCT",
          featureFlag: "promos",
        },
        {
          component: CNavItem,
          name: "Proveedores",
          to: "/suppliers",
          icon: <Truck className="mr-2" />,
          permissionKey: "CREATE_PRODUCT",
        },
      ];

      if (
        currentCompany?.config.stock &&
        [StockType.Limited, StockType.Mixed].includes(
          currentCompany.config.stock
        )
      ) {
        inventoryItems.push({
          component: CNavItem,
          name: "Stock",
          to: "/stock",
          icon: <Boxes className="mr-2" />,
          permissionKey: "LIST_WAREHOUSES",
        });
      }

      const inventoryItem = {
        component: CNavGroup,
        name: "Inventario",
        to: "/",
        items: inventoryItems,
      };

      const analyticsItem = {
        component: CNavGroup,
        name: "Métricas",
        to: "/",
        items: [
          {
            component: CNavItem,
            name: "Hoy",
            to: "/home",
            icon: <ChartNetwork className="mr-2" />,
            permissionKey: "LIST_ANALYTICS",
          },
          {
            component: CNavItem,
            name: "Dashboard",
            to: "/dashboard",
            icon: <LayoutDashboard className="mr-2" />,
            permissionKey: "LIST_ANALYTICS",
          },
          {
            component: CNavItem,
            name: "Reportes",
            to: "/reports",
            icon: <SquareChartGantt className="mr-2" />,
            permissionKey: "LIST_REPORTS",
          },
        ],
        keys: ["LIST_REPORTS", "LIST_ANALYTICS"],
      };

      if (!integrationsItem.items) {
        integrationsItem.items = [];
      }

      const companyId = currentCompany?.id;

      if (companyId) {
        integrationsItem.items.push({
          component: CNavItem,
          name: "Factura Electrónica",
          to: "/invoicing",
          icon: <Afip size={24} containerClassName="mr-2" />,
          permissionKey: "UPDATE_COMPANY",
        });
      }

      if (companyId && MeLiCompanies.includes(companyId)) {
        integrationsItem.items.push({
          component: CNavItem,
          name: "Mercado Libre",
          to: "/mercado-libre",
          icon: <MercadoLibre size={24} containerClassName="mr-2" />,
          permissionKey: "LIST_SALES",
        });
      }

      if (companyId && MePaCompanies.includes(companyId)) {
        integrationsItem.items.push({
          component: CNavItem,
          name: "Mercado Pago",
          to: "/mercado-pago",
          icon: <MercadoPago size={24} containerClassName="mr-2" />,
        });
      }

      if (companyId && MeShCompanies.includes(companyId)) {
        integrationsItem.items.push({
          component: CNavItem,
          name: "Mercado Shops",
          to: "/mercado-shops",
          icon: <MercadoShops size={24} containerClassName="mr-2" />,
          permissionKey: "LIST_SALES",
        });
      }

      if (companyId && RappiCompanies.includes(companyId)) {
        integrationsItem.items.push({
          component: CNavItem,
          name: "Rappi",
          to: "/rappi",
          icon: <Rappi size={24} containerClassName="mr-2" />,
        });
      }

      if (companyId && TiNuCompanies.includes(companyId)) {
        integrationsItem.items.push({
          component: CNavItem,
          name: "Tienda Nube",
          to: "/tienda-nube",
          icon: (
            <TiendaNube color="#FFFFFF" size={24} containerClassName="mr-2" />
          ),
        });
      }

      if (integrationsItem.items.length > 0) {
        top.push(integrationsItem);
      }

      const storeItem = {
        component: CNavGroup,
        name: "Mi Tienda",
        to: "/",
        items: [
          {
            component: CNavItem,
            name: "Cajas Registradoras",
            to: "/registers",
            icon: <Banknote className="mr-2" />,
            permissionKey: "LIST_REGISTERS",
          },
          {
            component: CNavItem,
            name: "Clientes",
            to: "/customers",
            icon: <BookUser className="mr-2" />,
            permissionKey: "CREATE_SALE",
          },
          {
            component: CNavItem,
            name: "Depósitos",
            to: "/warehouses",
            icon: <Warehouse className="mr-2" />,
            permissionKey: "LIST_WAREHOUSES",
          },
          {
            component: CNavItem,
            name: "Empleados",
            to: "/users",
            icon: <Users className="mr-2" />,
            permissionKey: "LIST_USERS",
          },
          {
            component: CNavItem,
            name: "Lista de Precios",
            to: "/price-lists",
            icon: <Book className="mr-2" />,
            permissionKey: "LIST_PRICE_LISTS",
          },
          {
            component: CNavItem,
            name: "Pedidos",
            to: "/orders",
            icon: <MailQuestion className="mr-2" />,
            permissionKey: "LIST_ORDERS",
          },
          {
            component: CNavItem,
            name: "Presupuestos",
            to: "/quotes",
            icon: <FileText className="mr-2" />,
            permissionKey: "LIST_QUOTES",
            featureFlag: "quotes",
          },
          {
            component: CNavItem,
            name: "Promociones Bancarias",
            to: "/banks",
            icon: <TicketPercent className="mr-2" />,
            featureFlag: "banks",
          },
          {
            component: CNavItem,
            name: "Puntos de Venta",
            to: "/stores",
            icon: <Store className="mr-2" />,
            permissionKey: "LIST_STORES",
          },
          {
            component: CNavItem,
            name: "Ventas",
            to: "/sales",
            icon: <Calculator className="mr-2" />,
            permissionKey: "LIST_SALES",
          },
        ],
      };

      top.push(inventoryItem);
      top.push(analyticsItem);

      top.push({
        component: CNavItem,
        name: "Mi Plan",
        to: "/plan",
        permissionKey: "UPDATE_COMPANY",
      });

      top.push(storeItem);

      return top;
    }

    top.push({
      component: CNavItem,
      name: "Auditoria",
      to: "/audit",
      icon: <Scale className="mr-2" />,
    });

    top.push({
      component: CNavItem,
      name: "Dashboard",
      to: "/dashboard",
      icon: <LayoutDashboard className="mr-2" />,
    });

    top.push({
      component: CNavItem,
      name: "Empresas",
      to: "/companies",
      icon: <Building2 className="mr-2" />,
      permissionKey: "LIST_COMPANIES",
    });

    top.push({
      component: CNavItem,
      name: "Grupos de Permisos",
      to: "/groups",
      icon: <FileKey2 className="mr-2" />,
    });

    top.push({
      component: CNavItem,
      name: "Planes",
      to: "/plans",
      icon: <CreditCard className="mr-2" />,
    });

    top.push({
      component: CNavItem,
      name: "Rubros",
      to: "/sectors",
      icon: <Folder className="mr-2" />,
    });

    top.push({
      component: CNavItem,
      name: "Stock",
      to: "/stock",
      icon: <Boxes className="mr-2" />,
      permissionKey: "LIST_WAREHOUSES",
    });

    top.push({
      component: CNavItem,
      name: "Tienda Nube",
      to: "/tienda-nube",
      icon: <TiendaNube color="#FFFFFF" size={24} containerClassName="mr-2" />,
    });

    top.push({
      component: CNavItem,
      name: "Usuarios",
      to: "/users",
      icon: <Users className="mr-2" />,
      permissionKey: "LIST_USERS",
    });

    top.push({
      component: CNavItem,
      name: "Ventas",
      to: "/sales",
      icon: <Calculator className="mr-2" />,
      permissionKey: "LIST_SALES",
    });
    return top;
  }, [
    MeLiCompanies,
    MePaCompanies,
    MeShCompanies,
    TiNuCompanies,
    RappiCompanies,
    currentCompany?.config.stock,
    currentCompany?.id,
    user?.isAdmin,
  ]);

  return {
    top: items.filter((item) => {
      let innerItem = item;

      if (innerItem.keys) {
        const hasPermission = innerItem.keys.some((key: string) =>
          user?.permissions?.find((permission) => permission.key === key)
        );

        if (!hasPermission) {
          return false;
        }
      }

      if (innerItem.items) {
        innerItem.items = innerItem.items.filter((subItem) => {
          if (!subItem.permissionKey || user?.isAdmin === true) {
            return true;
          }

          const hasSubPermission = user?.permissions?.find(
            (permission) => permission.key === subItem.permissionKey
          );

          const withFeatureFlag = subItem.featureFlag
            ? hasFlag(subItem.featureFlag)
            : true;

          if (hasSubPermission && withFeatureFlag) {
            return true;
          }

          return false;
        });
      }

      if (!innerItem.permissionKey || user?.isAdmin === true) {
        return true;
      }

      const hasPermission = user?.permissions?.find(
        (permission) => permission.key === innerItem.permissionKey
      );

      const withFeatureFlag = innerItem.featureFlag
        ? hasFlag(innerItem.featureFlag)
        : true;

      if (hasPermission && withFeatureFlag) {
        return true;
      }

      return false;
    }),
  };
};

export default useSidebar;
