import { gql } from "@apollo/client";
import { Customer } from "./customers";
import { Store } from "./stores";
import { Product } from "./products";
import { User } from "./users";
import { Nullable, WithTrash } from "src/types";
import { Sale } from "./sales";
import { PriceList } from "./price-lists";

export enum QuoteStatus {
  InProgress = "IN_PROGRESS",
  Sent = "SENT",
  Accepted = "ACCEPTED",
  Rejected = "REJECTED",
  Cancelled = "CANCELLED",
  SaleCreated = "SALE_CREATED",
}
export type Quote = WithTrash<{
  id: number;
  priceListId: number;
  storeId: number;
  customerId: Nullable<number>;
  saleId: Nullable<number>;

  recharge: number;
  discount: number;
  customerDiscount: number;
  date: Date;
  notes: string;
  status: QuoteStatus;
  pdfUrl: string;

  priceList: PriceList;
  store: Store;
  items: QuoteItem[];
  user: User;

  customer?: Customer;
  sale?: Sale;
}>;

export type QuoteItem = {
  id: number;
  quoteId: number;
  price: number;
  priceListItemId: number;
  product: Product;
  cost: number;
  profit: number;
  productId: number;
  quantity: number;
  recharge: number;
  discount: number;
  tax: number;
  summary: {
    tax: number;
    price: number;
    total: number;
    recharge: number;
    discount: number;
  };
};

export const LIST_QUOTES = gql`
  query quotes($filters: QuoteFilters) {
    data: quotes(filters: $filters) {
      data {
        id
        date
        discount
        status
        customer {
          id
          utid
          name
          lastname
          email
        }
        store {
          name

          company {
            name
          }
        }
        items {
          product {
            name
          }
          quantity
          price
          discount
        }
        user {
          id
          name
          lastname
        }
        deletedAt
      }
      meta {
        total
        lastPage
      }
    }
  }
`;

export const GET_QUOTE = gql`
  query quote($id: Int!) {
    data: quote(id: $id) {
      id
      date
      discount
      status
      notes
      pdfUrl
      saleId
      priceList {
        id
        name
      }
      customer {
        id
        utid
        name
        lastname
        email
      }
      store {
        id
        name

        company {
          name
        }
      }
      items {
        productId
        priceListItemId
        product {
          name
        }
        quantity
        price
        discount
      }
      user {
        id
        name
        lastname
      }
      deletedAt
    }
  }
`;

export const CREATE_QUOTE = gql`
  mutation createQuote($input: CreateQuoteInput!) {
    data: createQuote(input: $input) {
      id
    }
  }
`;

export const UPDATE_QUOTE = gql`
  mutation updateQuote($id: Int!, $input: UpdateQuoteInput!) {
    data: updateQuote(id: $id, input: $input) {
      id
    }
  }
`;

export const UPDATE_QUOTE_STATUS = gql`
  mutation updateQuoteStatus($id: Int!, $status: String!) {
    data: updateQuoteStatus(id: $id, status: $status) {
      id
    }
  }
`;
