import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { createStore as createVanilla } from "zustand/vanilla";
import { State, Set, GenericState, Get } from "./store.types";
import { layoutState } from "./layout";
import { usersInitialState, userStore } from "./users";
import { AppVersion } from "src/types";
import Config from "src/config";

const genericInitialState = {
  flags: {},
  commitHash: "",
};

export const genericState = (
  set: Set<GenericState>,
  get: Get<GenericState>
): GenericState => ({
  ...genericInitialState,

  hasFlag: (key: string) => get().flags[key] ?? false,

  setCommitHash: (commitHash: string) =>
    set((state) => ({
      ...state,
      commitHash,
    })),

  setFeatureFlags: (flags: Record<string, boolean>) =>
    set((state) => ({
      ...state,
      flags,
    })),

  resetStore: () =>
    set((state) => ({
      ...state,
      ...genericInitialState,
      ...usersInitialState,
    })),
});

const storeObject = (set: Set<State>, get: Get<State>): State => ({
  ...genericState(set, get),
  ...layoutState(set),
  ...userStore(set, get),
});

export const storeConfig = {
  name: `${Config.sessionKey}@${AppVersion}`,
  storage: createJSONStorage<State>(() => localStorage),
};

const clearOldKeys = () => {
  const envKey = Config.sessionKey;
  const currentKey = `${Config.sessionKey}@${AppVersion}`;

  const keys = Object.keys(localStorage).filter(
    (key) => key.includes(envKey) && key !== currentKey
  );

  keys.forEach((key) => {
    localStorage.removeItem(key);
  });
};

export const useAdminStore = create<
  State,
  [["zustand/devtools", never], ["zustand/persist", State]]
>(
  devtools(
    persist(
      (set, get) => ({
        ...storeObject(set, get),
      }),
      storeConfig
    )
  )
);

export const AdminStore = createVanilla<
  State,
  [["zustand/devtools", never], ["zustand/persist", State]]
>(
  devtools(
    persist(
      (set, get) => ({
        ...storeObject(set, get),
      }),
      storeConfig
    )
  )
);

clearOldKeys();
